<template>
  <div class="csn-user-account-menu">
    <div class="menu-list" :class="{ 'menu-list-five': isCasinoFive }">
      <ul>
        <li>
          <Route
            :to="{ name: routeName.USER_ACCOUNT_BALANCE }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-change-password"></span>
            {{ t('my_account_balance') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.userProfile }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-profile"></span>
            {{ t('my_profile') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.USER_CHANGE_PASSWORD }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-change-password"></span>
            {{ t('change_password') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.USER_GAME_ROUNDS }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-game-rounds"></span>
            {{ t('game_rounds') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.USER_TRANSACTIONS }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-transactions"></span>
            {{ t('transactions') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.USER_NEWSLETTER }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-newsletter"></span>
            {{ t('newsletter') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.USER_LIMITS }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-limits"></span>
            {{ t('limits') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.USER_ACCOUNT_VERIFICATION }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-verification"></span>
            {{ t('verify_account') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.USER_DOCUMENTS }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-documents"></span>
            {{ t('documents') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.USER_SELF_EXCLUSION }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-alert"></span>
            {{ t('self_exclusion') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.DEPOSIT }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-transactions"></span>
            {{ t('deposit') }}
          </Route>
        </li>
        <li>
          <Route
            :to="{ name: routeName.WITHDRAWAL }"
            @click.native="toggle"
            class="csn-user-account-menu-a"
          >
            <span class="casino-icon casino-icon-transactions"></span>
            {{ t('withdrawal') }}
          </Route>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  USER_ACCOUNT_MENU_LIST,
  RouteName,
  Module,
  LACKS_INFO,
  EventType,
  CASINO_FIVE,
} from '@/constants'
import { toPathCase } from '@/helpers'

export default {
  name: USER_ACCOUNT_MENU_LIST,
  components: {
    Route: () => import('@/components/Route'),
  },
  computed: {
    ...mapGetters({ lacksUserInfo: toPathCase([Module.USER, LACKS_INFO]) }),
    t() {
      return this.$createComponentTranslator(USER_ACCOUNT_MENU_LIST)
    },
    routeName() {
      return {
        userProfile: this.lacksUserInfo
          ? RouteName.USER_EDIT
          : RouteName.USER_ACCOUNT,
        USER_CHANGE_PASSWORD: RouteName.USER_CHANGE_PASSWORD,
        USER_GAME_ROUNDS: RouteName.USER_GAME_ROUNDS,
        USER_TRANSACTIONS: RouteName.USER_TRANSACTIONS,
        USER_NEWSLETTER: RouteName.USER_NEWSLETTER,
        USER_LIMITS: RouteName.USER_LIMITS,
        USER_ACCOUNT_VERIFICATION: RouteName.USER_ACCOUNT_VERIFICATION,
        USER_DOCUMENTS: RouteName.USER_DOCUMENTS,
        USER_SELF_EXCLUSION: RouteName.USER_SELF_EXCLUSION,
        USER_ACCOUNT_BALANCE: RouteName.USER_ACCOUNT_BALANCE,
        DEPOSIT: RouteName.DEPOSIT,
        WITHDRAWAL: RouteName.WITHDRAWAL,
      }
    },
    isCasinoFive: () => process.env.VUE_APP_THEME === CASINO_FIVE,
  },
  methods: {
    toggle() {
      this.$emit(EventType.TOGGLE)
    },
  },
}
</script>
